.home-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        padding: 4rem 1.5rem 0 1.5rem;
        font-size: 3.5rem;
        font-weight: bold;
        text-align: center;
    }

    .subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: forestgreen;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
        border-radius: 20px;
        font-style: italic;
    }
}

.home-cards {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    padding: 6rem 0;

    .home-cards-icons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .icon {
            max-width: 100px;
            width: 80%;
        }
    }

    .home-cards-grid {
        display: grid;
        @media (min-width: 1000px) {
            grid-template-columns: 48% 48%;
            grid-column-gap: 4%;
        }
        
        @media (max-width: 999px) {
            grid-template-columns: 100%;
        }
        grid-row-gap: 50px;

        .fullcard {
            display: flex;
            text-decoration: none;
            flex-direction: column;
            justify-self: center;
            width: 100%;
            max-width: 500px;

            .image {
                height: 150px;
                width: 100%;
            }

            .card {
                color: white;
                font-size: 1.5rem;
                text-align: center;
                padding: 2rem 1rem;
                background-color: #1395f2;
                border: none;
                border-radius: 0 0 10px 10px;
            }
        }
        .fullcard:hover {
            cursor: pointer;

            .card {
                color: #1395f2;
                background-color: white;
            }
        }
    }
}
